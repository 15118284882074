import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICustomer } from 'src/modules/customer/model/customer.model';
import { IMicrosoftSubscription, IMicrosoftSubscriptionOptions, IProvisionMicrosoftSubscription, IUpdateMicrosoftSubscription, IUpgradeMicrosoftSubscriptionPayload } from '../../models/microsoft-subscriptions.model';

@Injectable({
    providedIn: 'root',
})
export class MicrosoftSubscriptionsService {

    private readonly cache = new Map<string, any>();

    private readonly microsoftV2API: string = environment.apiUrl + 'v2/microsoft';

    constructor (private http: HttpClient) {
    }

    private getFromCache<T>(key: string): T | undefined {
        return this.cache.get(key);
    }

    private setToCache<T>(key: string, value: T): void {
        this.cache.set(key, value);
    }

    public getMicrosoftSubscriptions(customerId: ICustomer['id']): Observable<IMicrosoftSubscription[]> {
        return this.http.get<IMicrosoftSubscription[]>(this.microsoftV2API + '/customer/' + customerId + '/subscriptions');
    }

    public getMicrosoftSubscription(customerId: ICustomer['id'], subscriptionId: IMicrosoftSubscription['id']): Observable<IMicrosoftSubscription> {
        return this.http.get<IMicrosoftSubscription>(this.microsoftV2API + '/customer/' + customerId + '/subscriptions/' + subscriptionId);
    }

    public getMicrosoftSubscriptionOptions(customerId: ICustomer['id'], subscriptionId: IMicrosoftSubscription['id']): Observable<IMicrosoftSubscriptionOptions> {
        return new Observable<IMicrosoftSubscriptionOptions>(subscriber => {
            const cachedOptions = this.getFromCache<IMicrosoftSubscriptionOptions>(`${customerId}${subscriptionId}`);
            if (cachedOptions) {
                subscriber.next(cachedOptions);
                subscriber.complete();
            } else {
                // tslint:disable-next-line:max-line-length
                this.http.get<IMicrosoftSubscriptionOptions>(this.microsoftV2API + '/customer/' + customerId + '/subscriptions/' + subscriptionId + '/Options').subscribe(options => {
                    this.setToCache<IMicrosoftSubscriptionOptions>(customerId + subscriptionId, options);
                    subscriber.next(options);
                    subscriber.complete();
                });
            }
        });
    }

    public provisionMicrosoftSubscription(customerId: ICustomer['id'], provisionSubscription: IProvisionMicrosoftSubscription, acceptedTOS: boolean): Observable<IMicrosoftSubscription[]> {
        return this.http.post<IMicrosoftSubscription[]>(this.microsoftV2API + '/customer/' + customerId + '/subscriptions?accepted_tos=' + acceptedTOS, [provisionSubscription]);
    }

    public updateMicrosoftSubscription(customerId: ICustomer['id'], subscriptionId: IMicrosoftSubscription['id'], updateSubscription: IUpdateMicrosoftSubscription, acceptedTOS: boolean): Observable<IMicrosoftSubscription> {
        return this.http.put<IMicrosoftSubscription>(this.microsoftV2API + '/customer/' + customerId + '/subscriptions/' + subscriptionId + '?accepted_tos=' + acceptedTOS, updateSubscription);
    }

    public upgradeMicrosoftSubscription(customerId: ICustomer['id'], subscriptionId: IMicrosoftSubscription['id'], payload: IUpgradeMicrosoftSubscriptionPayload): Observable<any> {
        return this.http.patch<IMicrosoftSubscription>(this.microsoftV2API + '/customer/' + customerId + '/subscriptions/' + subscriptionId + '/Upgrade', payload);
    }
}
