import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICustomer } from 'src/modules/customer/model/customer.model';
import { ICatalogue, ICatalogueFilter, ICatalogueProduct, ICatalogueResult } from '../models/catalogue.model';
import { ICatalogueCategory } from '../models/categories.model';
import { buildQueryParamsByObject } from 'src/modules/shared/functions/util-functions';

@Injectable({
  providedIn: 'root'
})
export class CatalogueService {
  private readonly catalogueAPI: string = environment.apiUrl + 'v2/catalogue';

  constructor(private http: HttpClient) { }

  public getCategories(): Observable<ICatalogueCategory[]> {
    return this.http.get<ICatalogueCategory[]>(this.catalogueAPI + "/Categories");
  }

  public getCatalogueProduct(productId: ICatalogueProduct['id'], customerId: ICustomer['id']): Observable<ICatalogue> {
    return this.http.get<ICatalogue>(this.catalogueAPI + "/Products/" + productId + "?customerId=" + customerId);
  }

  public getCatalogueProducts(filterSettings: ICatalogueFilter): Observable<ICatalogueResult>{
    let httpParams = buildQueryParamsByObject(filterSettings);
    return this.http.get<ICatalogueResult>(this.catalogueAPI + "/Products", {params: httpParams});
  }

}


